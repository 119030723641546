
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      courseSelected: [],
      course_ids: [],
      select_course_ids: [],
      courseInfo: [
        {
          id: 1,
          name: "Welding & Fabrication (NE) ",
          code: "W&F (NE)",
          type: "New Entrant",
          Mgt: "Yes ",
          month: "3",
          hour: "360 ",
          ttd: "74",
          trance: "TR-1 ",
          target: "4115",
          unit_cost: "43413",
        },
        {
          id: 2,
          name: "Electrical & Navigation Equipment Installation (NE) ",
          code: "E&N (NE) ",
          type: "New Entrant",
          Mgt: "Yes",
          month: "3",
          hour: "360 ",
          ttd: "74 ",
          trance: "TR-1 ",
          target: "4115",
          unit_cost: "31674",
        },
        {
          id: 3,
          name: "Web Development (PHP-Laravel)",
          code: "WDP",
          type: "New Entrant",
          Mgt: "Yes",
          month: "3",
          hour: "360 ",
          ttd: "74 ",
          trance: "TR-1 ",
          target: "4115",
          unit_cost: "31674",
        },
        {
          id: 4,
          name: "Graphic Design",
          code: "GD",
          type: "New Entrant",
          Mgt: "Yes",
          month: "3",
          hour: "360 ",
          ttd: "74 ",
          trance: "TR-1 ",
          target: "4115",
          unit_cost: "31674",
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showCourseList: false,
      showMapCourseList: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    selectedCourse() {
      this.course_ids.map((item) => {
        var check = 0;
        this.courseSelected.map((s_item) => {
          if (s_item['id'] == item['id']) {
            check = 1;
          }
         });

         for( var i = 0; i < this.courseInfo.length; i++){ 
            if (this.courseInfo[i]['id'] === item['id']) { 
                this.courseInfo.splice(i, 1); 
            }
        }
         if(check == 0){
          this.courseSelected.push(item);
         }
      });
    },
    unselectedCourse() {
      this.select_course_ids.map((item) => {
        var check = 0;
        this.courseInfo.map((s_item) => {
          if (s_item['id'] == item['id']) {
            check = 1;
          }
         });

         for( var i = 0; i < this.courseSelected.length; i++){ 
            if (this.courseSelected[i]['id'] === item['id']) { 
                this.courseSelected.splice(i, 1); 
            }
        }
         if(check == 0){
          this.courseInfo.push(item);
         }
      });
    },
    selectedAllCourse() {
      Array.prototype.push.apply(this.courseSelected,  this.courseInfo);
      this.courseInfo.splice(0);
    },
    unselectedAllCourse() {
      Array.prototype.push.apply(this.courseInfo,  this.courseSelected);
      this.courseSelected.splice(0);
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseList() {
      this.showCourseList = true;
    },
     courseMapList() {
      this.showMapCourseList = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
